<template>
  <div>
    <!-- tab栏 -->
    <!-- <van-sticky>
      <van-tabs @click="onClickOnlyOne"
        color="#fb4d3f"
        title-active-color="#fb4d3f"
        swipeable
        swipe-threshold="4">
        <van-tab v-for="item in TitleList"
          :key="item.AKId"
          :name="item.AKId"
          :title="item.Name">
        </van-tab>
      </van-tabs>
    </van-sticky> -->
    <van-sticky>
      <van-search v-model="listfrom.starKindName"
        placeholder="请选择活动类型"
        @click="partyType = true"
        shape="round"
        readonly>
        <template #left-icon>
          <img src="http://jmylapi.zhsq.cloud//upload/files/2022/11/ZR2uIACzwrnj6968bb9baa4a2b20d6e27daf88a25a15.png"
            style="width:16px;height:16px;margin-right: 10px;margin-top: 5px;" />
        </template>
      </van-search>
    </van-sticky>
    <van-popup v-model="partyType"
      position="bottom"
      get-container="body">
      <van-picker show-toolbar
        :columns="partyTypeList"
        value-key="Title"
        @cancel="partyType = false"
        @confirm="onPartyType">
      </van-picker>
    </van-popup>
    <div class="information">
      <!-- 全部 -->
      <div class="center borderClass"
        v-for="item in list"
        :key="item.index">
        <router-link :to="'/fivestar/publicActicle/detail/' + item.ArticleId">

          <div class="Content">
            <div style="display: inline-block; width: 33%"
              class="ContentA"
              ref="getHeight">
              <img v-if="item.Thumb"
                style="border-radius:5px;min-height:80px;"
                :src="item.Thumb"
                alt="" />
            </div>
            <div style="display: inline-block; width: 63%"
              class="ContentB">
              <div class="centerTitle van-ellipsis--l2">
                {{ item.Title }}
              </div>
              <span class="centertime van-multi-ellipsis--l2"
                style="margin:5px 0px">{{ item.Digest }}</span>
            </div>

            <span class="centertime"
              style="float: right; margin-top: -15px;color:#999999;font-size:11px;">{{
              item.IssTime
            }}</span>
          </div>
        </router-link>
      </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WxGetWxArticlePage, WeGetPBActivityPage } from "@/api/RealInfo";
export default {
  data () {
    return {
      akId: 0, //tab栏
      activeName: "",
      list: {}, //资讯列表 
      partyType: false,
      listfrom: {
        page: 1,
        limit: 99,
        starKind: '',
        starKindName: '',
      }, //查询传参
      hidden: true, //没有更多数据了
      TitleList: [],
      partyTypeList: [
        { Id: 1, Title: '支部过硬星' },
        { Id: 2, Title: '共建共享星' },
        { Id: 3, Title: '平安法治星' },
        { Id: 4, Title: '幸福和谐星' },
        { Id: 5, Title: '宜业兴业星' },
      ],
    };
  },
  filters: {
    timeFilter (value) {
      if (value != undefined) {
        // let gstime = value.replace('T', ' ')
        let gstime = value.substring(0, 10);
        return gstime;
      }
    }
  },
  methods: {
    // 动态修改微信网页标题
    changeTitleClick (val) {
      document.setTitle = function (t) {
        document.title = t;
        var i = document.createElement("iframe");
        i.style.display = "none";
        i.onload = function () {
          setTimeout(function () {
            i.remove();
          }, 9);
        };
        document.body.appendChild(i);
      };
      setTimeout(function () {
        if (val == 1) {
          document.setTitle("支部过硬星");
        } else if (val == 2) {
          document.setTitle("共建共享星");
        } else if (val == 3) {
          document.setTitle("平安法治星");
        } else if (val == 4) {
          document.setTitle("幸福和谐星");
        } else if (val == 5) {
          document.setTitle("宜业兴业星");
        }
      }, 1);
    },
    // 活动分类
    getPBActivityList: function () {
      WeGetPBActivityPage({ PAKind: 2, }).then((res) => {
        this.TitleList = res.data.data;
        if (this.TitleList[0].Children != '0') {
          for (let i = 0; i < this.TitleList.length; i++) {
            const element = this.TitleList[i];
            if (element.Name == '支部过硬星') {
              console.log(element);
              this.TitleList = element.Children
              this.listfrom.akId = element.Children[0].AKId
            }
          }
          // this.listfrom.akId = this.TitleList[0].Children[0].AKId
        } else {

        }
        this.getList();
        // this.TitleList = [
        //   { AKId: 1, Name: "争当好书记" },
        //   { AKId: 2, Name: "建设好班子" },
        //   { AKId: 3, Name: "锻造好队伍" },
        //   { AKId: 4, Name: "严格党组织生活" },
        //   { AKId: 5, Name: "建全体制机制" },
        // ]
      })
    },
    onPartyType (val) {
      console.log(val);
      this.partyType = false
      this.listfrom.starKind = val.Id
      this.listfrom.starKindName = val.Title
      this.getList()
    },
    // 获取分页列表
    getList () {

      WxGetWxArticlePage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].Thumb == "") {
                  this.list[i].ThumbUrl =
                    "https://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png";
                }
              }
            }
          }
        })
        .catch(() => { });
    },
    topClick: function (name, title) {
      for (let index = 0; index < this.TitleList.length; index++) {
        const element = this.TitleList[index];
        if (element.Children.length != '0') {
          if (element.AKId == name) {
            this.listfrom.akId = element.Children[0].AKId
            this.activeName = element.Children[0].AKId
            this.getList()
          } else {
            this.listfrom.akId = element.AKId
            this.getList()
          }
        }

      }
    },
    // tab点击切换事件
    onClick (name, title) {
      this.list = []
      this.listfrom.akId = name
      this.listfrom.page = 1
      this.getList()
    },
    onClickOnlyOne (name, title) {
      this.list = []
      this.listfrom.akId = name
      this.listfrom.page = 1
      this.getList()
    },
  },
  mounted () {
    // 动态修改微信网页标题
    // this.changeTitleClick(this.$route.params.Id)
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    // console.log(this.$route.params.Id);
    // this.getPBActivityList()
    // this.getList(this.$route.params.Id);
    this.getList();
  },
};
</script>
<style scoped>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.information {
  width: 95%;
  margin: auto;
}
.borderClass {
  padding: 0;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.borderClass .Content {
  padding: 8px;
  margin-top: 10px;
}
</style>